<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
           
     emp:{},
     monthly_salary_details:[],
     salary_items:[],
     total:0

    };
  },
    methods: {
        get() {
            this.http.get('emps/' + this.$route.params.id).then(res => {
                this.total = res.total
                this.emp = res.data
                this.monthly_salary_details = res.data.monthly_salary_details

                for (let i = 0; i < this.monthly_salary_details?.length; i++) {
                  for (let j = 0; j < this.monthly_salary_details[i]?.salary_item.salary_item_degrees.length; j++) {
                    this.salary_items.push({name:this.monthly_salary_details[i]?.salary_item.name,value:this.monthly_salary_details[i]?.salary_item.salary_item_degrees[j].value})                

                  }

                  // for (let j = 0; j < this.monthly_salary_details[i]?.salary_items?.length; j++) {
                    
                  // }
                }
                console.log(this.salary_items);
            })
        }
    },
      created(){
     this.get()
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Employee Salary Details" />
  

<table class="table">
<thead>
<tr class=" text-light text-center" style="background-color: #2a3042 !important;">
<th scope='col'>Id</th>
<th scope='col'>Code</th>
<th scope='col'>Name</th>
<th scope='col'>Degree</th>
<th scope='col'>Month</th>
<th scope='col'>Year</th>
<th scope='col' v-for="salary in salary_items" :key="salary">{{salary.name}}</th>
<th scope='col'>Total</th>
<th scope="col"></th>
</tr>
<tr class="text-center">
  <td scope="col">1</td>
  <td scope="col">{{emp.code}}</td>
  <td scope="col">{{emp.name}}</td>
  <td scope="col">{{emp.degree}}</td>
  <td scope="col">{{monthly_salary_details[0]?.month}}</td>
  <td scope="col">{{monthly_salary_details[0]?.year}}</td>
  <td scope="col" v-for="salary in salary_items" :key="salary">{{salary.value.toLocaleString()}}</td>
  <td scope="col">{{total.toLocaleString()}}</td>
</tr>
</thead>
<tbody> 

</tbody>
</table>
<!-- end  table -->




<!--end-->
  </Layout>
</template>




